@import '../../scss/animations.scss';

div.paper {
  position: relative;
  padding: 2rem 2.4rem;
  border-radius: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  animation: fade-in-from-top 0.8s cubic-bezier(.55,.5,.45,.5);
  @media (min-width: 425px) {
    min-width: 480px;
  }
}

.waves {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7%;
}

use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;

  &:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }

  &:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }

  &:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
}