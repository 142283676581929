.homePage {
  position: relative;
  height: calc(100% - 64px);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (max-width: 576px)  {
  .homePage {
    height: initial;
  }
}