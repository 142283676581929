@import "fonts";
@import "muiComponents";

* {
  box-sizing: border-box;
}

html, #root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: #f4f6f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;

  * {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #d8dfec;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid #a2b3c2;
      border-radius: 2px;
    }
  }
}

//global
.d-none {
  display: none !important;
}

a {
  text-decoration: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//temp
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

//lender credit policy

.css-1n4h3ua-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  background-color: #6683ea !important;
  z-index: 1;
}

#superset-widget iframe {
  width: 100%;
  height: 100vh;
}

// Preview docx wrapper
.docx-wrapper {
  background: none !important;
  padding: 0 !important;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}

.MuiDataGrid-cell {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  display: flex !important;
  align-items: center;
}

// GrapesJS

.gjs_wrapper {
  height: 75vh !important;
}

.gjs-one-bg {
  background-color: #fff !important;
}

.gjs-four-color {
  color: #6683ea !important;
}

.gjs-pn-views {
  max-width: 175px !important;
  width: auto !important;
  overflow: auto;
  @media screen and (max-width: 768px) {
    max-width: 100px !important;
  }
}

.gjs-pn-views-container {
  width: 18% !important;
}

.gjs-pn-devices-c {
  @media screen and (max-width: 450px) {
    overflow: auto;
    max-width: 45px !important;
  }
}

.gjs-pn-options {
  overflow: auto;
  right: 20% !important;
  @media screen and (max-width: 768px) {
    max-width: 400px !important;
  }
  @media screen and (max-width: 600px) {
    max-width: 150px !important;
  }
}

.gjs-radio-item input:checked+.gjs-radio-item-label {
  background-color: rgba(255,255,255,.7) !important;
}

.gjs-cm-editor#gjs-cm-css #gjs-cm-title {
  color: #7b6200 !important;
}
