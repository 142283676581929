@import "../../../../../scss/variables.scss";

.inputPhone {
  width: 100% !important;
  margin-top: 10px;
}

.btnFlag {
  height: 110%;
}

.file__input::-webkit-file-upload-button, .file__webcam {
  text-transform: uppercase;
  padding: 6px 16px;
  border-radius: 5px;
  line-height: 1.75rem;
  color: #fff;
  background-color: $primary;
  border: none;
  cursor: pointer;
  transition: 0.2s;
}

.file__input::-webkit-file-upload-button:hover, .file__webcam:hover   {
  background-color: darken($color: $primary, $amount: 5);
}

.file__webcam {
  margin: 0 20px 10px 0;
}

.imgPreviewBlock { 
  display: flex; 
  align-items: center; 
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  padding-right: 10px; 
  border: 1px solid #9e9e9e; 
  border-radius: 5px;
  background-color: #f5f5f5; 
}

.imgPreview {
  display: block;
  width: 100%;
  height: 68px;
  object-fit: fill;
  border-radius: 5px 0 0 5px;
}

.imgPreviewTextBlock { 
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}